import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { BellOutlined } from "@ant-design/icons";
import { Dropdown, Badge, List, Card, Typography, Spin, Divider } from "antd";
import { getNotificationPath } from "../../../utils/notificationRoutes";
import {
  getUserNotifications,
  markNotificationAsRead,
  deleteNotification,
} from "../../../api/notification.api";

const { Text } = Typography;

interface Notification {
  id: number;
  recordId: number;
  title: string;
  reportName: string;
  time: string;
  read: boolean;
  reportType: string;
}

const NotificationBell: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userId = useSelector((state: RootState) => state.user.user?.id);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!userId) return;

    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const data = await getUserNotifications(userId);
        setNotifications(
          data.map((notif) => ({
            id: notif.id,
            recordId: notif.recordId,
            title: notif.title,
            reportName: notif.reportName ?? "",
            time: new Date(notif.createdAt).toLocaleString(),
            read: notif.readStatus,
            reportType: notif.reportType,
          }))
        );
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [userId, location.pathname]);

  const unreadCount = notifications.filter((n) => !n.read).length;

  const handleNotificationClick = (notification: Notification) => {
    setVisible(false);
    setNotifications((prev) =>
      prev.filter((notif) => notif.id !== notification.id)
    );
    navigate(getNotificationPath(notification.reportType, notification.recordId));
    markNotificationAsRead(notification.id).catch((error) =>
      console.error("Error marking as read:", error)
    );
    deleteNotification(notification.id).catch((error) =>
      console.error("Error deleting notification:", error)
    );
  };

  const renderNotificationList = (data: Notification[]) => (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <React.Fragment key={item.id}>
          <List.Item
            style={{
              backgroundColor: item.read ? "#fff" : "#f0f6ff",
              cursor: "pointer",
              padding: "10px 16px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => handleNotificationClick(item)}
          >
            <div style={{ flexGrow: 1 }}>
              <Text style={{ fontSize: "14px", fontWeight: 500 }}>
                {item.reportName}
              </Text>
              <br />
              <Text style={{ fontSize: "12px", color: "#777" }}>
                {item.time}
              </Text>
            </div>
          </List.Item>
          <Divider style={{ margin: 0 }} />
        </React.Fragment>
      )}
    />
  );

  const menu = (
    <Card
      style={{
        width: 320,
        maxHeight: 400,
        overflowY: "auto",
        borderRadius: 8,
      }}
      bodyStyle={{ padding: 0 }}
    >
      <div
        style={{
          padding: "12px 16px",
          borderBottom: "1px solid #f0f0f0",
          fontSize: "14px",
          fontWeight: 600,
        }}
      >
        Notifications
      </div>
      {loading ? (
        <Spin size="large" style={{ display: "block", margin: "20px auto" }} />
      ) : (
        renderNotificationList(notifications)
      )}
    </Card>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement="topLeft"
      arrow
      visible={visible}
      onVisibleChange={setVisible}
    >
      <Badge count={unreadCount} offset={[8, 0]}>
        <BellOutlined style={{ fontSize: 20, cursor: "pointer" }} />
      </Badge>
    </Dropdown>
  );
};

export default NotificationBell;
