import { httpApi } from "./http.api";

// Response interface for notifications
export interface INotification {
  id: number;
  userId: number;
  recordId: number;
  title: string;
  message: string;
  status: string;
  reportName?: string;
  createdAt: string;
  readStatus: boolean;
  reportType: string;
}

// Fetch user notifications
export const getUserNotifications = (userId: number): Promise<INotification[]> =>
  httpApi.get<INotification[]>(`notification/${userId}`).then(({ data }) => data);

export const markNotificationAsRead = (notificationId: number): Promise<{ success: boolean }> =>
  httpApi
    .put<{ success: boolean }>(`notification/${notificationId}/mark-as-read`)
    .then(({ data }) => data);

// Delete notification
export const deleteNotification = (notificationId: number): Promise<{ message: string }> =>
  httpApi.delete<{ message: string }>(`notification/${notificationId}`).then(({ data }) => data);
