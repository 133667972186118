/* eslint-disable max-len */
import React from "react";
import Icon from "@ant-design/icons";
import { GetProps } from "antd";
import logo from "../assets/Images/logo_verifyMe.png";
type CustomIconComponentProps = GetProps<typeof Icon>;

const PanListSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M3 10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H13C16.7712 2 18.6569 2 19.8284 3.17157C21 4.34315 21 6.22876 21 10V14C21 17.7712 21 19.6569 19.8284 20.8284C18.6569 22 16.7712 22 13 22H11C7.22876 22 5.34315 22 4.17157 20.8284C3 19.6569 3 17.7712 3 14V10Z"
      stroke="#7C7C7C"
      strokeWidth="1.5"
    />
    <path d="M8 12H16" stroke="#7C7C7C" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M8 8H16" stroke="#7C7C7C" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M8 16H13" stroke="#7C7C7C" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export const PanListSvgIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={PanListSvg} {...props} />
);

const LegalVerificationSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M7 17.9983C4.82497 17.9862 3.64706 17.8897 2.87868 17.1213C2 16.2426 2 14.8284 2 12L2 
      8C2 5.17157 2 3.75736 2.87868 2.87868C3.75736 2 5.17157 2 8 2L16 2C18.8284 2 20.2426 2 
      21.1213 2.87868C22 3.75736 22 5.17157 22 8V12C22 14.8284 22 16.2426 21.1213 17.1213C20.3915 
      17.8512 19.2921 17.9748 17.3197 17.9957L16.5 17.9983"
      stroke="#7C7C7C"
      strokeWidth="1.5"
    />
    <path d="M9 6L15 6" stroke="#7C7C7C" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M7 9.5H17" stroke="#7C7C7C" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M10.8905 13.9454C11.5297 13.4006 12.4699 13.4006 13.1092 13.9454C13.3831 14.1788 13.7237 14.3199 14.0824 14.3485C14.9197 
      14.4153 15.5845 15.0802 15.6513 15.9174C15.6799 16.2761 15.821 16.6167 16.0544 16.8907C16.5992 17.5299 16.5992 18.4701 16.0544 
      19.1093C15.821 19.3833 15.6799 19.7239 15.6513 20.0826C15.5845 20.9198 14.9197 21.5847 14.0824 21.6515C13.7237 21.6801 13.3831 
      21.8212 13.1092 22.0546C12.4699 22.5994 11.5297 22.5994 10.8905 22.0546C10.6165 21.8212 10.276 21.6801 9.91719 21.6515C9.07997 
      21.5847 8.41515 20.9198 8.34834 20.0826C8.31971 19.7239 8.17863 19.3833 7.94519 19.1093C7.40043 18.4701 7.40043 17.5299 7.94519 
      16.8907C8.17863 16.6167 8.31971 16.2761 8.34834 15.9174C8.41515 15.0802 9.07997 14.4153 9.91719 14.3485C10.276 14.3199 10.6165 
      14.1788 10.8905 13.9454Z"
      stroke="#7C7C7C"
      strokeWidth="1.5"
    />
    <path
      d="M10.5 18.2L11.3571 19L13.5 17"
      stroke="#7C7C7C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LegalVerificationSvgIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={LegalVerificationSvg} {...props} />
);

const BusinessVerificationSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M3 12C3 15.7712 3 19.6569 4.31802 20.8284C5.63604 22 7.75736 22 12 22C16.2426 22 18.364 22 19.682 20.8284C21 19.6569 21 15.7712 21 12"
      stroke="#7C7C7C"
      strokeWidth="1.5"
    />
    <path
      d="M14.6603 14.2019L20.6676 12.3997C21.2631 12.2211 21.5609 12.1317 21.7498 11.9176C21.7866 11.8759 21.8199 
      11.8312 21.8492 11.784C22 11.5415 22 11.2307 22 10.6089C22 8.15877 22 6.9337 21.327 6.10659C21.1977 5.94763 
      21.0524 5.80233 20.8934 5.67298C20.0663 5 18.8412 5 16.3911 5H7.60893C5.15877 5 3.9337 5 3.10659 5.67298C2.94763 
      5.80233 2.80233 5.94763 2.67298 6.10659C2 6.9337 2 8.15877 2 10.6089C2 11.2307 2 11.5415 2.15078 11.784C2.18015 
      11.8312 2.21341 11.8759 2.25021 11.9176C2.43915 12.1317 2.7369 12.2211 3.3324 12.3997L9.33968 14.2019"
      stroke="#7C7C7C"
      strokeWidth="1.5"
    />
    <path
      d="M6.5 5C7.32344 4.97913 8.15925 4.45491 8.43944 3.68032C8.44806 3.65649 8.4569 3.62999 8.47457 3.57697L8.50023 
      3.5C8.54241 3.37344 8.56351 3.31014 8.58608 3.254C8.87427 2.53712 9.54961 2.05037 10.3208 2.00366C10.3812 2 10.4479 
      2 10.5814 2H13.4191C13.5525 2 13.6192 2 13.6796 2.00366C14.4508 2.05037 15.1262 2.53712 15.4144 3.254C15.4369 
      3.31014 15.458 3.37343 15.5002 3.5L15.5259 3.57697C15.5435 3.62968 15.5524 3.65656 15.561 3.68032C15.8412 4.45491 
      16.6766 4.97913 17.5 5"
      stroke="#7C7C7C"
      strokeWidth="1.5"
    />
    <path
      d="M14 12.5H10C9.72386 12.5 9.5 12.7239 9.5 13V15.1615C9.5 15.3659 9.62448 15.5498 9.8143 15.6257L10.5144 15.9058C11.4681 16.2872 12.5319 16.2872 13.4856 15.9058L14.1857 15.6257C14.3755 15.5498 14.5 15.3659 14.5 15.1615V13C14.5 12.7239 14.2761 12.5 14 12.5Z"
      stroke="#7C7C7C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
export const BusinessVerificationSvgIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={BusinessVerificationSvg} {...props} />
);

const IdentityVerificationSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="2" stroke="#7C7C7C" strokeWidth="1.5" />
    <path
      d="M13 15C13 16.1046 13 17 9 17C5 17 5 16.1046 5 15C5 13.8954 6.79086 13 9 13C11.2091 13 13 13.8954 13 15Z"
      stroke="#7C7C7C"
      strokeWidth="1.5"
    />
    <path
      d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12C22 15.7712 22 17.6569 20.8284 18.8284C19.6569 20 17.7712 20 14 20H10C6.22876 20 4.34315 20 3.17157 18.8284C2 17.6569 2 15.7712 2 12Z"
      stroke="#7C7C7C"
      strokeWidth="1.5"
    />
    <path d="M19 11H15" stroke="#7C7C7C" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M19 8H14" stroke="#7C7C7C" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M19 15H16" stroke="#7C7C7C" strokeWidth="1.5" strokeLinecap="round" />
    <circle cx="19" cy="17" r="4" fill="#0E0E0E" stroke="#7C7C7C" strokeWidth="1.5" />
    <path
      d="M17.666 17.0002L18.4995 18.0002L20.3327 16.1113"
      stroke="#7C7C7C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const IdentityVerificationSvgIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={IdentityVerificationSvg} {...props} />
);

const TeamsSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="6" r="4" stroke="#7C7C7C" strokeWidth="1.5" />
    <path
      d="M18 9C19.6569 9 21 7.88071 21 6.5C21 5.11929 19.6569 4 18 4"
      stroke="#7C7C7C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M6 9C4.34315 9 3 7.88071 3 6.5C3 5.11929 4.34315 4 6 4"
      stroke="#7C7C7C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <ellipse cx="12" cy="17" rx="6" ry="4" stroke="#7C7C7C" strokeWidth="1.5" />
    <path
      d="M20 19C21.7542 18.6153 23 17.6411 23 16.5C23 15.3589 21.7542 14.3847 20 14"
      stroke="#7C7C7C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M4 19C2.24575 18.6153 1 17.6411 1 16.5C1 15.3589 2.24575 14.3847 4 14"
      stroke="#7C7C7C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const TeamsSvgIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={TeamsSvg} {...props} />
);

const DollarSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" stroke="#7C7C7C" strokeWidth="1.5" />
    <path d="M12 17V17.5V18" stroke="#7C7C7C" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M12 6V6.5V7" stroke="#7C7C7C" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5"
      stroke="#7C7C7C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const DollarSvgIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DollarSvg} {...props} />
);

const DotSvg = () => (
  <svg
    className="bi bi-dot"
    fill="currentColor"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
  </svg>
);
export const DotSvgIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DotSvg} {...props} />
);

export const BlackLogo = () => <img className="login-card w-34" src={logo} alt="" />;
export const VerifymeLogo = () => <img className="login-card w-34" src={logo} alt="logo" />;

const RupeeSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    zoomAndPan="magnify"
    viewBox="0 0 30 30.000001"
    height="24"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
  >
    <defs>
      <clipPath id="39c678f4ab">
        <path
          d="M 8.574219 4.875 L 21.433594 4.875 L 21.433594 24.160156 L 8.574219 24.160156 Z M 8.574219 4.875 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="8a23bb07b7">
        <path
          d="M 0.484375 0 L 29.5 0 L 29.5 29.015625 L 0.484375 29.015625 Z M 0.484375 0 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>

    <g clipPath="url(#39c678f4ab)">
      <path
        fill="none"
        stroke="#7C7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        d="M 17.5625 7.023438 L 20.761719 7.023438 L 21.433594 4.878906 L 9.246094 4.878906 L 8.574219 7.023438 L 12.859375 7.023438 C 14.507812 7.023438 15.890625 8.089844 16.394531 9.5625 L 9.246094 9.5625 L 8.574219 11.707031 L 16.476562 11.707031 C 16.058594 13.320312 14.605469 14.515625 12.859375 14.515625 L 8.574219 14.515625 L 8.574219 16.160156 L 16.476562 24.152344 L 19.289062 24.152344 L 19.289062 23.882812 L 11.789062 16.390625 C 12.449219 16.390625 13.230469 16.390625 13.398438 16.390625 C 16.183594 16.390625 18.476562 14.363281 18.925781 11.707031 L 20.761719 11.707031 L 21.433594 9.5625 L 18.882812 9.5625 C 18.671875 8.597656 18.203125 7.734375 17.5625 7.023438 Z M 17.5625 7.023438 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>

    <g clipPath="url(#8a23bb07b7)">
      <path
        fill="none"
        stroke="#7C7C7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        d="M 14.992188 0 C 6.988281 0 0.484375 6.503906 0.484375 14.507812 C 0.484375 22.511719 6.988281 29.015625 14.992188 29.015625 C 22.996094 29.015625 29.5 22.511719 29.5 14.507812 C 29.5 6.503906 22.996094 0 14.992188 0 Z M 14.992188 0.628906 C 22.664062 0.628906 28.867188 6.835938 28.867188 14.507812 C 28.867188 22.179688 22.664062 28.386719 14.992188 28.386719 C 7.320312 28.386719 1.113281 22.179688 1.113281 14.507812 C 1.113281 6.835938 7.320312 0.628906 14.992188 0.628906 Z M 14.992188 0.628906 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export const RupeeSvgIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={RupeeSvg} {...props} />
);
