import React from "react";
import { Flex } from "antd";
import LoginImg from "../../assets/Images/login.png";
import NetImg from "../../assets/Images/net.png";

const AuthLayout = ({ children, isReverse = false }) => {
  return (
    <Flex className={isReverse ? "flex-row-reverse" : ""}>
      {/* First Flex Block with Image */}
      <div
        className="w-1/2 h-screen bg-primary-light hidden md:flex flex-col justify-between"
      >
        <div className="flex items-center justify-center flex-grow">
          <img
            src={LoginImg}
            className="max-w-[837px] object-contain max-h-[80vh]"
            alt="Login"
          />
        </div>
        <div className="w-full text-center text-sm text-gray-900 p-4">
          © 2025 <strong>Verifyme.ai</strong>. All Rights Reserved.
          <a
            href="https://www.verifyme.ai/privacy-policy.html"
            style={{
              margin: "0 0.5em",
              textDecoration: "none",
              color: "#333",
            }}
            target="_blank" // Open link in a new tab
            rel="noopener noreferrer"
            onMouseEnter={(e) => ((e.target as HTMLElement).style.color = "#AC3CE1")}
            onMouseLeave={(e) => ((e.target as HTMLElement).style.color = "inherit")}
          >
            Privacy Policy
          </a>
          |
          <a
            href="https://www.verifyme.ai/terms-and-conditions.html"
            style={{
              margin: "0 0.5em",
              textDecoration: "none",
              color: "#333",
            }}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={(e) => ((e.target as HTMLElement).style.color = "#AC3CE1")}
            onMouseLeave={(e) => ((e.target as HTMLElement).style.color = "inherit")}
          >
            Terms and Conditions
          </a>
          {/* |
          <a
            href="https://www.verifyme.ai/contact-us.html"
            style={{
              margin: "0 0.5em",
              textDecoration: "none",
              color: "#333",
            }}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={(e) => ((e.target as HTMLElement).style.color = "#AC3CE1")}
            onMouseLeave={(e) => ((e.target as HTMLElement).style.color = "inherit")}
          >
            Contact
          </a> */}
          |
          <a
            href="https://www.verifyme.ai/about.html"
            style={{
              margin: "0 0.5em",
              textDecoration: "none",
              color: "#333",
            }}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={(e) => ((e.target as HTMLElement).style.color = "#AC3CE1")}
            onMouseLeave={(e) => ((e.target as HTMLElement).style.color = "inherit")}
          >
            About
          </a>
          {/* |
          <a
            href="https://www.verifyme.ai/pricing.html"
            style={{
              margin: "0 0.5em",
              textDecoration: "none",
              color: "#333",
            }}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={(e) => ((e.target as HTMLElement).style.color = "#AC3CE1")}
            onMouseLeave={(e) => ((e.target as HTMLElement).style.color = "inherit")}
          >
            Pricing
          </a> */}
        </div>
      </div>

      {/* Second Flex Block with Children */}
      <Flex
        align="center"
        justify="center"
        className="w-full md:w-1/2 h-screen p-4"
        style={{
          backgroundImage: `url(${NetImg})`,
          objectPosition: "center",
          objectFit: "contain",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="p-1">{children}</div>
      </Flex>
    </Flex>
  );
};

export default AuthLayout;
