import React from "react";

export const Label = ({ text }) => {
  return (
    <span
      style={{
        fontSize: "14px",
        fontWeight: 400,
        color: "rgba(0, 0, 0, 0.50)",
      }}
    >
      {text}
    </span>
  );
};

export const Value = ({ text }) => {
  return (
    <span
      style={{
        fontSize: "14px",
        fontWeight: 400,
        color: "#000",
      }}
    >
      {text}
    </span>
  );
};

export const Heading = ({ text }) => {
  return (
    <h3
      style={{
        fontSize: "16px",
        fontWeight: 500,
        color: "#000",
      }}
    >
      {text}
    </h3>
  );
};

export const LegalHeading = ({ text }) => {
  return (
    <span>
      {text.toUpperCase()}
    </span>
  );
};

export const Title2 = ({ text }) => {
  return <span className="md:text-xl sm:text-lg text-base font-normal text-black">{text}</span>;
};

export const PageTitle = ({ text }) => {
  return <span className="text-h4 font-bold">{text}</span>;
};

export const MediumHeading = ({ text }) => {
  return <span className="md:text-xl sm:text-lg text-base font-normal capitalize">{text}</span>;
};

export const BaseHeading = ({ text }) => {
  return <span className="text-h7 font-semibold capitalize">{text}</span>;
};

export const BaseValue = ({ text }) => {
  return <span className="text-h7 font-normal capitalize">{text}</span>;
};
