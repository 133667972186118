import React from "react";
import { LinkedinFilled, MailOutlined } from "@ant-design/icons";

const Footer = () => {
  return (
    <footer
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1em 2em",
        backgroundColor: "rgb(240,240,240)",
        fontSize: "14px",
      }}
    >
      {/* Left Side: Logo, Copyright, and Links */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>&copy; 2025 Verifyme.ai. All Rights Reserved.</span>
        <nav>
          <a
            href="https://www.verifyme.ai/privacy-policy.html"
            style={{
              margin: "0 0.5em",
              textDecoration: "none",
              color: "#333", // Adding subtle color to the links
            }}
            target="_blank" // Open link in a new tab
            rel="noopener noreferrer"
            onMouseEnter={(e) => ((e.target as HTMLElement).style.color = "#AC3CE1")}
            onMouseLeave={(e) => ((e.target as HTMLElement).style.color = "inherit")}
          >
            Privacy Policy
          </a>
          |
          <a
            href="https://www.verifyme.ai/terms-and-conditions.html"
            style={{
              margin: "0 0.5em",
              textDecoration: "none",
              color: "#333", // Adding subtle color to the links
            }}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={(e) => ((e.target as HTMLElement).style.color = "#AC3CE1")}
            onMouseLeave={(e) => ((e.target as HTMLElement).style.color = "inherit")}
          >
            Terms and Conditions
          </a>
          {/* |
          <a
            href="https://www.verifyme.ai/contact-us.html"
            style={{
              margin: "0 0.5em",
              textDecoration: "none",
              color: "#333", // Adding subtle color to the links
            }}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={(e) => ((e.target as HTMLElement).style.color = "#AC3CE1")}
            onMouseLeave={(e) => ((e.target as HTMLElement).style.color = "inherit")}
          >
            Contact
          </a> */}
          |
          <a
            href="https://www.verifyme.ai/about.html"
            style={{
              margin: "0 0.5em",
              textDecoration: "none",
              color: "#333", // Adding subtle color to the links
            }}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={(e) => ((e.target as HTMLElement).style.color = "#AC3CE1")}
            onMouseLeave={(e) => ((e.target as HTMLElement).style.color = "inherit")}
          >
            About
          </a>
          {/* |
          <a
            href="https://www.verifyme.ai/pricing.html"
            style={{
              margin: "0 0.5em",
              textDecoration: "none",
              color: "#333", // Adding subtle color to the links
            }}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={(e) => ((e.target as HTMLElement).style.color = "#AC3CE1")}
            onMouseLeave={(e) => ((e.target as HTMLElement).style.color = "inherit")}
          >
            Pricing
          </a> */}
        </nav>
      </div>

      {/* Right Side: Social Media Icons */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <a
          href="https://www.linkedin.com/company/verifyme.ai/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            margin: "0 0.5em",
            color: "#6A1B9A", // Changed to a vibrant purple for contrast
            transition: "transform 0.3s ease",
          }}
          onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.2)")}
          onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
        >
          <LinkedinFilled style={{ fontSize: "24px" }} />
        </a>
        <a
          href="mailto:contact@verifyme.ai"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            margin: "0 0.5em",
            color: "#6A1B9A", // Matching color for mail icon
            transition: "transform 0.3s ease",
          }}
          onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.2)")}
          onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
        >
          <MailOutlined style={{ fontSize: "24px" }} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
