import { Flex, Layout } from "antd";
import { MediumHeading } from "components/Common/HeadingAndCommonDetails/Label";
import React from "react";
const { Content } = Layout;

const VerificationLayout = ({ children, heading, prefix, suffix }: any) => {
  return (
    <Flex vertical className="bg-white rounded-[30px] pt-0 pb-4 px-4 card-wrapper min-h-[75vh]">
      <Flex gap={10} justify="space-between" align="center" className="p-3 border-b">
        <Flex align="center" gap={10}>
          {prefix && prefix}
          <MediumHeading text={heading} />
        </Flex>
        {suffix && suffix}
      </Flex>
      <Content className="w-full">{children}</Content>
    </Flex>
  );
};

export default VerificationLayout;
