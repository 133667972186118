import { Avatar, Dropdown, Flex, MenuProps, Tooltip } from "antd";
import React from "react";
import { PageTitle } from "../HeadingAndCommonDetails/Label";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { useAppSelector } from "hooks/reduxHooks";
import NotificationBell from "../NotificationBell/NotifictaionBell";

const CommonPageHeader = () => {
  const navigate = useNavigate();
  // const selectedReduxLabel = useAppSelector((state) => state.backgroundCheck.selectedLabel);
  // const selectedBusinessReduxLabel = useAppSelector( (state) => state.backgroundCheck.selectedBusinessLabel);
  // const selectedBulkReduxLabel = useAppSelector((state) => state.backgroundCheck.selectedBulkLabel);
  const user = useAppSelector((state) => state.auth.userDetails);
  const availableCredit = useAppSelector((state) => state.auth.availableCredit);
  const location = useLocation();
  const path = location.pathname;
  const segments = path.split("/").filter(Boolean);
  // const isBreadcramb = true;

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          onClick={() => {
            navigate("/profile");
          }}
        >
          Profile
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            navigate("/logout");
          }}
        >
          Logout
        </a>
      ),
      // icon: <SmileOutlined />,
      // disabled: true,
    },
  ];

  const menuItems = [
    {
      key: "/",
      label: "Legal Verification",
    },
    {
      key: "/profile",
      label: "Profile",
    },
    {
      key: "/legal-verification",
      label: "Legal Verification",
    },
    {
      key: "/legal-verification/add-legal-verification",
      label: "Add Legal Verification",
    },
    {
      key: "/legal-verification/:id",
      label: "Legal Verification Details",
    },
    {
      key: "/bulk-verification",
      label: "Bulk Verification",
      children: [
        /* ----------- Bulk PAN Verification ------------ */
        {
          key: "/bulk-verification/bulk-pan-verification",
          label: "PAN Verification",
        },
        {
          key: "/bulk-verification/bulk-pan-verification/add-record",
          label: "Upload Record",
        },
        {
          key: "/bulk-verification/bulk-pan-verification/record/:id",
          label: "PAN Record",
        },
        {
          key: "/bulk-verification/bulk-pan-verification/details/:id",
          label: "PAN Details",
        },

        /* ----------- Bulk PAN to Udyam Verification ------------ */
        {
          key: "/bulk-verification/bulk-pan-to-udyam",
          label: "PAN to Udyam Verification",
        },
        {
          key: "/bulk-verification/bulk-pan-to-udyam/add-record",
          label: "Upload Record",
        },
        {
          key: "/bulk-verification/bulk-pan-to-udyam/record/:id",
          label: "PAN to Udyam Record",
        },
        {
          key: "/bulk-verification/bulk-pan-to-udyam/details/:id",
          label: "PAN to Udyam Details",
        },
        /* ----------- Bulk Mobile to Udyam Verification ------------ */
        {
          key: "/bulk-verification/bulk-mobile-to-udyam",
          label: "Mobile to Udyam Verification",
        },
        {
          key: "/bulk-verification/bulk-mobile-to-udyam/add-record",
          label: "Upload Record",
        },
        {
          key: "/bulk-verification/bulk-mobile-to-udyam/record/:id",
          label: "Mobile to Udyam Record",
        },
        {
          key: "/bulk-verification/bulk-mobile-to-udyam/details/:id",
          label: "Mobile to Udyam Details",
        },
      ],
    },
    {
      key: "/add-background-verification",
      label: "Background Verification",
      children: [
        {
          key: "1",
          label: "PAN",
        },
        {
          key: "2",
          label: "Aadhaar",
        },
        {
          key: "3",
          label: "Voter",
        },
        // { key: "4", icon: <BankOutlined />, label: "Passport" },
        {
          key: "5",
          label: "Driving License",
        },
        {
          key: "6",
          label: "RC Number",
        },
        { key: "7", label: "UAN" },
        {
          key: "8",
          label: "Credit Score",
        },
        {
          key: "9",
          label: "EPFO Verification",
        },
      ],
    },
    {
      key: "/add-business-verification",
      label: "Business Verification",
    },
    {
      key: "/aml-verification",
      label: "AML Verification",
    },
    {
      key: "/teams",
      label: "Teams",
    },
    {
      key: "/teams/add-teams",
      label: "Add Teams",
    },
    {
      key: "/order-list",
      label: "Recharge",
    },
    {
      key: "/order-list/recharge",
      label: "Recharge Your Account",
    },
    {
      key: "/transaction-history",
      label: "Transaction History",
    },
    {
      key: "/full-background-verification",
      label: "Full Background Verification",
    },
    {
      key: "/full-background-verification/add-full-background-verification",
      label: "Add Full Background Verification",
    },
    {
      key: "/full-background-verification/:id",
      label: "Full Background Verification Details",
    },
  ];

  // Helper function to find the label
  const getLabel = (pathname) => {
    for (const item of menuItems) {
      // Direct match
      if (item.key === pathname) return item.label;

      // Handle dynamic routes ("/:id" -> any value)
      const dynamicRouteMatch =
        item.key.includes(":") &&
        new RegExp(`^${item.key.replace(/:\w+/g, "[^/]+")}$`).test(pathname);

      if (dynamicRouteMatch) {
        return item.label;
      }

      if (item.children) {
        const child = item.children.find((childItem) => {
          if (childItem.key === pathname) return true;
          return (
            childItem.key.includes(":") &&
            new RegExp(`^${childItem.key.replace(/:\w+/g, "[^/]+")}$`).test(pathname)
          );
        });

        if (child) return child.label;
      }
    }
    return null; // Default fallback
  };

  // const findObjectByKey = (key, value) => {
  //   return menuItems.find((item) => item[key] === value);
  // };

  // const getSubLabel = () => {
  //   if (segments?.length > 1) {
  //     return (
  //       <Breadcrumb>
  //         {segments
  //           .filter(Boolean) // Remove empty segments
  //           .map((segment, index) => {
  //             const selectedPath = "/" + segments.slice(0, index + 1).join("/");
  //             const normalizedPath = selectedPath.replace(/:\w+/g, "");
  //             const label = getLabel(normalizedPath);

  //             return label ? (
  //               <Breadcrumb.Item key={index}>
  //                 <span className="text-base text-[#828282] capitalize">{label}</span>
  //               </Breadcrumb.Item>
  //             ) : null; // Skip if label is empty
  //           })}
  //       </Breadcrumb>
  //     );
  //   }

  //   const specialCases = {
  //     "add-background-verification": selectedReduxLabel,
  //     "add-business-verification": selectedBusinessReduxLabel,
  //     "bulk-verification": selectedBulkReduxLabel,
  //   };

  //   const mainSegment = segments[0];
  //   if (specialCases[mainSegment]) {
  //     const label = findObjectByKey("key", `/${mainSegment}`);
  //     return (
  //       <Breadcrumb>
  //         <Breadcrumb.Item>
  //           <span className="text-base text-[#828282] capitalize">
  //             {label?.label || mainSegment}
  //           </span>
  //         </Breadcrumb.Item>
  //         <Breadcrumb.Item>
  //           <span className="text-base text-[#828282] capitalize">{specialCases[mainSegment]}</span>
  //         </Breadcrumb.Item>
  //       </Breadcrumb>
  //     );
  //   }

  //   return null; // Return null if no segments are found
  // };

  const currentLabel = getLabel(location.pathname);
  const breadcrumbItems = segments
    .filter(Boolean)
    .map((segment, index) => {
      const selectedPath = "/" + segments.slice(0, index + 1).join("/");
      const normalizedPath = selectedPath.replace(/:\w+/g, "");
      return getLabel(normalizedPath);
    })
    .filter(Boolean);

  const mainHeading = breadcrumbItems.length > 0 ? breadcrumbItems[0] : currentLabel;
  const subHeading = breadcrumbItems.length > 1 ? breadcrumbItems.slice(0).join(" / ") : null;
  return (
    <Flex gap={10} align="center" justify="space-between" className="mb-3">
      <Flex vertical>
        <PageTitle text={mainHeading} />
        {subHeading && <span className="text-base text-gray-500">{subHeading}</span>}
      </Flex>
      <Flex gap={30} align="center">
        <NotificationBell />

        <Tooltip title="Credit Balance">
          <div
            onClick={() => navigate("/order-list/recharge")}
            className="text-primary-color cursor-pointer text-h5 border border-primary-color rounded-lg bg-primary-color bg-opacity-15 flex items-center justify-center min-w-24 gap-2 p-[5px]"
          >
            <PlusOutlined />
            <span>
              {new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(availableCredit)}
            </span>
          </div>
        </Tooltip>

        <Flex gap={12} align="center">
          {/* <Flex align="center" justify="center" className="bg-white rounded-full w-[50px] h-[50px]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.7491 9.70957V9.00497C18.7491 5.13623 15.7274 2 12 2C8.27256 2 
                5.25087 5.13623 5.25087 9.00497V9.70957C5.25087 10.5552 5.00972 11.3818 
                4.5578 12.0854L3.45036 13.8095C2.43882 15.3843 3.21105 17.5249 4.97036 
                18.0229C9.57274 19.3257 14.4273 19.3257 19.0296 18.0229C20.789 17.5249 
                21.5612 15.3843 20.5496 13.8095L19.4422 12.0854C18.9903 11.3818 18.7491 
                10.5552 18.7491 9.70957Z"
                stroke="#111111"
                strokeWidth="1.5"
              />
              <path
                d="M7.5 19C8.15503 20.7478 9.92246 22 12 22C14.0775 22 15.845 20.7478 16.5 19"
                stroke="#111111"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </Flex> */}
          <Dropdown menu={{ items }}>
            <div className="flex items-center gap-3 cursor-pointer">
              <div className="relative">
                <div className="rounded-lg w-[50px] h-[50px] overflow-hidden ">
                  <Avatar size={50}>
                    {user?.firstName && user.firstName.length > 0 ? user?.firstName[0] : ""}{" "}
                  </Avatar>
                </div>
                {/* <div className="w-3.5 h-3.5 bg-primary-green flex items-center justify-center absolute rounded-full bottom-0 right-0">
                <p className="w-2.5 h-2.5 bg-success-secondary rounded-full"></p>
              </div> */}
              </div>
              <div>
                <p className="text-text-secondary text-sm font-semibold">
                  {user?.firstName + " " + user?.lastName}
                </p>
                <span className="text-text-tertiary text-sm text-[#828282]"> Active</span>
              </div>
            </div>
          </Dropdown>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CommonPageHeader;
