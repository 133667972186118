export const getNotificationPath = (reportType: string, recordId: number): string => {
  const routes: Record<string, string> = {
    BULK_PAN_VERIFICATION: `/bulk-verification/bulk-pan-verification/record/${recordId}`,
    BULK_PAN_UDYAM_VERIFICATION: `/bulk-verification/bulk-pan-to-udyam/record/${recordId}`,
    BULK_MOBILE_UDYAM_VERIFICATION: `/bulk-verification/bulk-mobile-to-udyam/record/${recordId}`,
    bgv_VERIFICATION: `/full-background-verification/${recordId}`,
    CRIME:`/legal-verification/${recordId}`
  };

  return routes[reportType] || "/";
};
