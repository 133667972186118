import axios, { AxiosError } from "axios";
import { readToken } from "./services/localStorage.service";
import { ApiError } from "./ApiError";
import { notification } from "antd";
import { IdentityVerificationData } from "components/ResultDisplay/ResultDisplay";

// const blockedPANs = ["DOJPP0074M", "CDAPP6862N1"];

export const baseURL = "https://plankton-app-ygamm.ondigitalocean.app/";
// export const baseURL = "http://localhost:4000/";


export const IDENTITY_STATUS = 0;

export interface ApiResponse<T> {
  data: T;
}

export interface ApiErrorData {
  message: string;
}

export const httpApi = axios.create({
  baseURL: baseURL,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
httpApi.interceptors.request.use((config: any) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${readToken()}`,
  };
  return config;
});

httpApi.interceptors.response.use(
  (response) => response,
  (error: AxiosError<ApiErrorData>) => {
    if (error?.response?.status === 401) {
      window.location.href = "/logout";
    }
    if (error?.response?.status === 403) {
      notification.error({
        message: "Access Denied",
        description:
          error.response.data.message || "You do not have permission to access this resource.",
      });
    }
    throw new ApiError<ApiErrorData>(
      error && error.response?.data?.message ? error.response?.data?.message : error.message
    );
  }
);

export const checkBlockedPAN = (
  pan: string,
  apiCall: () => Promise<IdentityVerificationData | null>
): Promise<IdentityVerificationData | null> => {
  return new Promise((resolve) => {
      resolve(apiCall());
  });
};
